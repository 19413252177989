<template>
  <div class="DataScreenBox">
    <div id="DataScreenContainer" class="DataScreenBox-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DataScreenBox',
    mounted() {
      this.init();
    },
    deleted() {
      window.onresize = null;
    },
    methods: {
      init(){
        // 初始化自适应  ----在刚显示的时候就开始适配一次
        this.handleScreenAuto();
        // 绑定自适应函数   ---防止浏览器栏变化后不再适配
        window.onresize = () => this.handleScreenAuto();
        },
      // 数据大屏自适应函数
      handleScreenAuto() {
        const designDraftWidth = 1920; // 设计稿的宽度
        const designDraftHeight = 1080; // 设计稿的高度
        // 根据屏幕的变化适配的比例
        const scale =
          document.documentElement.clientWidth /
            document.documentElement.clientHeight <
          designDraftWidth / designDraftHeight
            ? document.documentElement.clientWidth / designDraftWidth
            : document.documentElement.clientHeight / designDraftHeight;
        // 缩放比例
        document.querySelector(
          '#DataScreenContainer'
        ).style.transform = `scale(${scale}) translate(-50%)`;
      },
    },
  }
</script>

<style lang="scss" scoped>
.DataScreenBox{
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: url('~@/assets/images/dataScreen/screen_bg.png') center center no-repeat;
  background-size: 100% 100%;
  &-container{
    display: flex;
    flex-direction: column;
    width: 1920px; //设计稿的宽度
    height: 1080px; //设计稿的高度
    padding: 0 20px 20px;
    transform-origin: 0 0;
    position: absolute;
    left: 50%;
    box-sizing: border-box;
  }
}
</style>
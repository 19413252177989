//节流实现
export const throttle = function (fn, delay=200){
  let flag = true
  return function() {
    let th = this;
    let args = arguments;
    if(!flag){
      //未超过时间间隔，flag无效，不执行fn
      return false 
    }
    fn.apply(th, args)
    flag = false    //在时间间隔内把状态位flag设为无效（false）
    setTimeout(() => {
      flag = true   //超过时间间隔把状态位flag设为有效（true）
    }, delay)
  }
}

export const formatNum = (num, decimals = 2) => {
  const toFixedNum = (+num).toFixed(decimals);
  const numStr = toFixedNum.toString().split('.'); //先转为字符串，再分开整数和小数部分
  let numInt = numStr[0]; // 整数部分
  let numDec = numStr[1] || ''; // 小数部分
  if(numDec) {
    numDec = `.${numDec.padStart(decimals, '0').slice(0, decimals)}`;
    // numDec = numStr.length > 1 ? '.'+numStr[1] : '.' ; // 小数部分，只有原数字存在小数点的时候，分割完长度才会大于1
    // while (decimals - numDec.length)  numDec += '0';  //补0，只有整数的补2个，有一位小数的补1个
  }
  let resultInt = ''; //存储整数部分处理结果
  while (numInt.length > 3) {     //当剩余整数部分长度大于3时继续处理
      resultInt = ','+numInt.slice(-3)+resultInt;  //把后三位截出来，和处理结果拼在一起，前面加逗号
      numInt = numInt.slice(0, -3); // 剩下的部分是除去刚刚截掉的3位的部分
  }
  return `${ numInt }${ resultInt }${ numDec }`; //结果是“剩余的不足3位的整数”+“处理好的整数结果”+“小数部分”
}
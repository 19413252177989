<template>
  <div class="DataTable">
    <div class="table_body" :style="{ height: `${height}px` }">
      <div class="table_th">
        <template v-for="(column, index) in columns">
          <div :key="index" class="th_style">{{ column.label }}</div>
        </template>
      </div>
      <div class="table_main_body">
        <div class="table_inner_body" :style="{top: tableTop + 'px'}">
          <div class="table_tr" v-for="(item, index) in tableDataList" :key="index">
            <template v-for="(column, index) in columns">
              <div v-if="column.icon" :key="index" class="tr">
                <i class="el-icon-caret-top" v-if="item[column.prop] > 0"></i>
                <i class="el-icon-caret-bottom" v-else></i>
                {{ item[column.prop] }}
              </div>
              <div v-else :key="index" class="tr">
                <template v-if="column.isFormat">
                  {{ formatNum(+(item[column.prop] || 0)) }}
                </template>
                <template v-else-if="column.key === 'price'">
                  <template v-if="item[column.prop]">
                    <template v-if="column.tradeMode">
                      {{ formatNum(+(item[column.prop])) }}/{{ item[`currency${column.tradeMode}`] }}
                    </template>
                    <template v-else>{{ formatNum(+(item[column.prop])) }}</template>
                  </template>
                  <template v-else>
                    ---
                  </template>
                </template>
                <template v-else>{{ item[column.prop] }}</template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { formatNum } from '@/utils';
  export default {
    name: 'DataTable',
    props: {
      columns: {
        type: Array,
        default: () => []
      },
      tableData: {
        type: Array,
        default: () => []
      },
      visibleSize: {
        type: Number,
        default: 4,
      },
      height: {
        type: Number,
        default: 250,
      }
    },
    data() {
      return {
        formatNum,
        tableTimer: null,
        tableListSize: 0,
        tableTop: 0,
        lineHeight: 48,
        tableTimerInterval: 100,
        tableDataList: [],
      }
    },
    watch: {
      tableData: {
        immediate: true,
        deep: true,
        handler() {
          this.$nextTick(() => {
            this.tableActionFun();
          })
        }
      }
    },
    methods: {
      tableActionFun() {
        this.tableListSize = this.tableData.length;
        if (this.tableListSize > this.visibleSize) {
          this.tableDataList = this.tableDataList.concat(this.tableData);
          this.tableTimerFun();
        } else {
          this.tableDataList = this.tableDataList.concat(this.tableData);
          // this.fillTableList();
        }
      },
      fillTableList() {
        let addLength = this.visibleSize - this.tableListSize;
        for (let i = 0; i < addLength; i++) {
          this.tableData.push({
            planNo: "-",
            type: "-",
            startDate: "-",
            endDate: "-",
            process: "-"
          });
        }
      },
      tableTimerFun() {
        var count = 0;
        this.tableTimer = setInterval(() => {
          if (count < (this.tableData.length / 2) * this.lineHeight) {
            this.tableTop -= 1;
            count++;
          } else {
            count = 0;
            this.tableTop = 0;
          }
        }, this.tableTimerInterval);
      },
    },
    beforeDestroy() {
      clearInterval(this.tableTimer);
    },
  }
</script>

<style lang="scss" scoped>
.DataTable{
  .table_body {
    width: 100%;
    // height: 250px;
    overflow: hidden;
    .table_th {
      width: 100%;
      display: flex;
      height: 40px;
      line-height: 40px;
      .th_style {
        // width: 100px;
        flex: 1;
        color: #19CCDB;
        font-weight: bold;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
        padding: 0 3px;
        // text-align: center;
      }
    }
    .table_main_body {
      width: 100%;
      height: 294px;
      overflow: hidden;
      position: relative;
      .table_inner_body {
        width: 100%;
        position: absolute;
        left: 0;
        .table_tr {
          display: flex;
          height: 40px;
          line-height: 40px;
          color: #fff;
          font-size: 14px;
          // background: rgba(3, 145, 167, 0.1);
          // border: 1px solid rgb(4, 114, 131);
          margin-top: 7px;
          .tr {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            box-sizing: border-box;
            padding: 0 3px;
            // text-align: center;
            font-size: 14px;
            color: #fff;
            .el-icon-caret-top{
              font-size: 18px;
              color: #01BB7B;
            }
            .el-icon-caret-bottom{
              font-size: 18px;
              color: #FF4266;
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <div ref="BarLineChartRef" class="BarLineChart"></div>
</template>

<script>
  import $echartsConfig from '@/utils/echartsConfig';
  export default {
    name: 'BarLineChart',
    props: {
      optionsData: {
        type: Array,
        required: true,
      },
      legend: {
        type: Object,
        default: () => {}
      },
      colors: {
        type: Array,
        default: () => []
      },
      barWidth: {
        type: Number,
        default: 20,
      },
      type: {
        type: String,
        default: 'bar',
      },
      axisValueName: {
        type: String,
        default: '',
      },
      selectedMode: {
        type: String,
        default: 'multiple',
      }
    },
    data() {
      return {
        echartsView: null,
        pieOption: null,
      }
    },
    watch: {
      optionsData: {
        deep: true,
        handler() {
          this.updateChartOption();
        }
      }
    },
    mounted () {
      this.initChart();
    },
    methods: {
      initChart() {
        this.echartsView = $echartsConfig.echarts.init(this.$refs.BarLineChartRef);
        // this.initChartOption();
        this.initEmptyChart();
      },
      initEmptyChart() {
        const emptyOption = {
          title: {
            text: "暂无数据",
            x: "center",
            y: "center",
            textStyle: {
              color: "#fff",
              fontWeight: "normal",
              fontSize: 16,
            },
          },
        };
        this.echartsView.setOption(emptyOption);
      },
      initChartOption() {
        const baseOption = {
          title: '',
          tooltip: {
            ...$echartsConfig.tooltip,
            ...(
              this.selectedMode === 'single' ?
              {
                formatter: (params) => {
                  const [ curItem ] = params;
                  const item = this.optionsData.find(item => (item.name === curItem.name && item.tradeMode === curItem.seriesName));
                  return `
                    ${curItem.name}<br />
                    单量：${curItem.value || '---'}<br />
                    USD：${item?.priceUSD || '---'}<br />
                    JPY：${item?.priceJPY || '---'}
                  `;
                }
              }
              : {}
            )
          },
          grid: {
            ...$echartsConfig.grid,
          },
          legend: {
            ...$echartsConfig.legend,
            selectedMode: this.selectedMode,
            itemGap: 30
          },
          xAxis: {
            ...$echartsConfig.axis,
            show: true,
            type: 'category',
            // inverse: true,
            data: [],
            axisLabel: {
              ...$echartsConfig.axis.axisLabel,
              formatter: function(value) {
                return value.length > 4 ? `${value.slice(0, 4)}...` : value;
              }
            }
          },
          yAxis: {
            type: 'value',
            show: true
          },
          series: [],
        };
        this.echartsView.setOption(baseOption);
      },
      updateChartOption(){
        this.echartsView.clear();
        const { legendData, xData, seriesData } = this.getFormatOptions(this.optionsData);
        if(seriesData.length) {
          this.initChartOption();
        } else {
          this.initEmptyChart();
          return false;
        }
        let option = {
          legend: {
            data: legendData,
          },
          xAxis: {
            data: xData
          },
          yAxis: {
            name: this.axisValueName || '',
          },
          series: seriesData.map((data, index) => {
            return {
              name: legendData[index],
              type: this.type,
              barWidth: this.barWidth,
              yAxisIndex: 0,
              itemStyle: {
                color: typeof this.colors[index] === 'string' 
                  ?
                    this.colors[index]
                  :
                    $echartsConfig.linearGradientColor(this.colors[index])

              },
              data,
            }
          })
        };
        this.echartsView.setOption(option, false);
      },
      getFormatOptions(data) {
        let xData = data.map(item => item.name);
        let legendData = Object.values(this.legend);
        let seriesData = Object.keys(this.legend).map(name => {
          return data.map(item => item[name]);
        });
        return {
          legendData,
          xData,
          seriesData
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.BarLineChart{
  width: 100%;
  height: calc(100% - 50px);
}
</style>
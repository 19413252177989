<template>
  <div class="Header">
    {{ title }}
    <div class="time">
      <span class="icon"></span>
      <span class="date">{{ curDate }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Header',
    props: {
      title: {
        type: String,
        default: ''
      },
      curDate: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
      }
    },
  }
</script>

<style lang="scss" scoped>
.Header{
  position: relative;
  width: 100%;
  height: 96px;
  line-height: 75px;
  background: url('~@/assets/images/dataScreen/title-border.png') center center no-repeat;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  .time{
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    .icon{
      background: url('~@/assets/images/dataScreen/date.svg') center center no-repeat;
    }
    .date{
      font-size: 18px;
      color: #fff;
    }
  }
}
</style>